<template>
  <div class="page">
    <modal-tags></modal-tags>
  </div>
</template>
<script>
import ModalTags from '../components/ModalTags.vue'

export default {
  name: 'MyAccountTags',
  components: {
    ModalTags
  }
}
</script>
<style lang="scss" scoped>

</style>
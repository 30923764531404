<template>
  <div class="cellphone page">
    <header class="cellphone__header">
      <div class="back" @click="this.$router.push('/auth')"></div>
      <div class="registration" @click="this.$router.push('/register/welcome')">Registration</div>
    </header>
    <div class="cellphone__content">
      <h1 class="title">Login By Phone</h1>
      <p class="text-base">
        Enter the phone number and password you specified during registration
      </p>
      <input-base
          type="phone"
          title="Cellphone Number"
          placeholder="XXX-XXX-XX-XX"
          @input="onInput($event)">
      </input-base>
      <div class="error">Invalid Phone Number</div><br>
      <input-base
          type="password"
          title="Password"
          placeholder="Enter password"
          @input="onInput($event)">
      </input-base>
      <div class="error">Invalid Password</div>
    </div>
    <button-base
        class="cellphone__button"
        :disabled="cellphone === ''"
        @click="this.$router.push('')">
      Log Into your Account
    </button-base>

    <div class="cellphone__link">
      <router-link
          to=""
          class="link"
          @click="this.$router.push('/LoginByEmail')">
        Login By Email
      </router-link>
    </div>
  </div>
</template>
<script>

import ButtonBase from '../components/UI/ButtonBase.vue'
import InputBase from '../components/UI/InputBase.vue'

export default {
  name: 'LoginByPhone',
  components: {
    InputBase,
    ButtonBase
  },
  data() {
    return {
      cellphone: ''
    }
  },
  methods: {
    onInput(e) {
      this.cellphone = e.value
    }
  }
}
</script>
<style lang="scss" scoped>

.page {
  width: 100%;
  height: 100vh;
  background-color: var(--color-light-0);
}

.cellphone {
  padding: 8vh 4% 10vh;
  display: flex;
  flex-direction: column;



  .registration {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: -0.408px;
    color: #D89972;

  }
  .error {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #E74949;
    margin-top: 2px;

  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-left: 4.8%;
    margin-right: 5.3%;
    margin-bottom: 9%;
  }

  .close {
    margin-top: 1px;
  }

  &__content {
    text-align: center;
    flex-grow: .3;
    margin: 0 auto;
    width: 92%;

    .title {
      margin-bottom: 8px;
    }

    .text-base {
      margin-bottom: 32px;
    }
  }

  &__button {
    width: 92%;
    margin: 0 auto;
    margin-bottom: 18px;
  }

  &__link {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--color-divider);
  }
}
</style>
<template>
  <div class="wrapper page">
    <header class="header">
      <div class="back" @click="this.$router.push('/restaurants')"></div>
      <h1 class="title">Рестораны</h1>
      <div></div>
    </header>
    <div class="card"></div>
    <modal-restaurants :begin="true"></modal-restaurants>
  </div>
</template>
<script>
import ModalRestaurants from "../components/ModalRestaurants.vue"

export default {
  name: "RestaurantsOnCardBeginModal",
  components: {
    ModalRestaurants
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6vh 5.3% 6px;
  border-bottom: 2px solid var(--color-divider);

  .text-base {
    font-size: 16px;
    color: #d89972;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
  }

  .viewing {
    margin-top: 1px;
  }
}

.card {
  padding: 0 0 0;
  display: flex;
  flex-direction: column;
  height: calc(40vh);
  background: no-repeat url("../assets/img/restaurant/CardBig.png");
  background-size: cover;

  &__content {
    text-align: center;
    flex-grow: 2;
  }
}
</style>

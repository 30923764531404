<template>
  <modal-base :header="false" :begin="begin">
    <div class="rest">
      <div class="rest__content">
        <div class="rest__header">
          <div class="fix"></div>
        </div>
        <input-base
          class="rest__search"
          type="text"
          :label="false"
          :search="true"
          placeholder="Поиск"
        ></input-base>
        <div class="rest__kinds">
          <div class="rest__kind text-base">Японская кухня</div>
          <div class="rest__kind text-base">Европейская кухня</div>
          <div class="rest__kind text-base">Российская кухння</div>
        </div>
        <div class="rest__list">
          <div class="rest__item" v-for="item of rest_list" :key="item.id">
            <div class="rest__photo">
              <img :src="item.img" />
              <div class="rest__distance text-base">{{ item.distance }} м</div>
            </div>
            <div class="rest__bio">
              <span class="rest_list__name text-base">{{ item.name }}</span>
              <div class="rest__rating">
                <div class="rest__stars" v-for="i in 4" :key="i">
                  <RatingStar :yellow="yellow" />
                </div>
                <div class="rest__stars" v-for="i in 1" :key="i">
                  <RatingStar :yellow="!yellow" />
                </div>
                <span class="rest__rating-num">{{ item.rating }}</span>
                <span class="rest__rating-count"
                  >{{ item.ratings }} оценки</span
                >
              </div>
              <span class="rest__persons"
                >{{ item.persons_now }} человека сейчас</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal-base>
</template>
<script>
import ModalBase from "./UI/ModalBase.vue";
import InputBase from "../components/UI/InputBase";
import RatingStar from "../components/UI/RatingStar.vue";

export default {
  name: "ModalRestaurants",
  components: {
    ModalBase,
    InputBase,
    RatingStar,
  },
  props: {
    begin: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      yellow: true,
      rest_list: [
        {
          id: 1,
          name: "Fire Lake Grill House & Cocktail Bar",
          persons_now: 2,
          img: require("../assets/img/restaurant/Rest.png"),
          distance: 500,
          rating: 4.7,
          ratings: 241,
        },
        {
          id: 2,
          name: "Relax",
          persons_now: 2,
          img: require("../assets/img/restaurant/Cafe.png"),
          distance: 500,
          rating: 4.7,
          ratings: 241,
        },
      ],
    };
  },
  methods: {
    onStar(e) {
      console.log(e.target.svg);
    },
  },
};
</script>
<style lang="scss" scoped>
.rest {
  display: flex;
  flex-direction: column;
  padding: 1vh 4% 6vh;
  height: calc(100vh - 87px);

  &__header {
    display: flex;
    justify-content: space-around;

    .fix {
      width: 32px;
      height: 3px;
      border-radius: 100px;
      background-color: var(--color-tretiary);
    }
  }

  &__content {
    text-align: left;
    flex-grow: 2;

    .input {
      height: auto;
      margin: 8px 0 16px;
    }
  }

  &__kinds {
    display: flex;
    position: relative;
    overflow: overlay;
    justify-content: space-between;
  }

  &__kind {
    background: var(--color-light-10);
    border-radius: 76px;
    padding: 7px 16px 4px;
    font-size: 13px;
    min-width: fit-content;
    margin-right: 8px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 18px;
  }

  &__item {
    min-height: 168px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    box-shadow: 0px 4px 24px rgba(78, 60, 33, 0.08);
    border-radius: 12px;
    margin-bottom: 16px;
  }

  &__bio {
    text-align: left;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  &__distance {
    position: absolute;
    background: rgba(54, 38, 20, 0.72);
    border-radius: 29px;
    padding: 4px 12px 0;
    top: 4%;
    left: 3%;
    color: var(--color-white);
    font-size: 13px;
  }

  &__photo {
    min-height: 160px;
    position: relative;

    img {
      width: 100%;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    width: 65%;
  }

  &__rating {
    display: flex;
    margin: 5px 0;
  }

  &__rating-num {
    margin: 0 12px 0 4px;
  }
}
</style>
<template>
  <div class="wrapper page">
    <header class="header">
      <div class="back" @click="this.$router.push('/profileviewblurcomplain')"></div>
      <h1 class="title">Terms of use</h1>
      <div></div>
    </header>
    <div class="terms">
      <div class="terms__content">
        <div class="terms__title text-base">Terms of use</div>
        <p class="text-base">
          If you want to keep your account,
          but hide it from others, you can freeze it. You can return to the app at any time If you want to keep your account, but hide it from others, you can freeze it. You can return to the app at any time
        </p>
        <p class="text-base">
          If you want to keep your account, but hide it from others, you can freeze it. You can return to the app at any time If you want to keep your account,
          but hide it from others, you can freeze it. You can return to the app at any time If you want to keep your account, but hide it from others, you can freeze it. You can return to the app at any time
        </p>
        <p class="text-base">
          If you want to keep your account, but hide it from others, you can freeze it. You can return to the app at any time If you want to keep your account,
          but hide it from others, you can freeze it. You can return to the app at any time If you want to keep your account, but hide it from others, you can freeze it. You can return to the app at any time
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TermsUse",
  components: {
    
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6vh 5.3% 6px;
  border-bottom: 2px solid var(--color-divider);
  margin-bottom: 27px;

  .text-base {
    font-size: 16px;
    color: #d89972;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
  }
}

.terms {
  display: flex;
  flex-direction: column;
  padding: 1vh 4% 6vh;
  height: calc(100vh - 56px);

  &__content {
    text-align: left;
    flex-grow: 2;
    margin: 0 auto;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}

</style>

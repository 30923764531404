<template>
  <div class="offers page">
    <header class="offers__header"></header>
    <div class="offers__content">
      <div class="offers__bio">
        <div class="title">Маргарита, 20</div>
        <div class="offers__img">
          <img src="../assets/img/offers_or_settings/Photo.png" />
        </div>
      </div>
      <br />
      <div class="offers__navs block">
        <div class="navs__item text-base active" @click="onActive($event)">Предложения</div>
        <div class="navs__item text-base" @click="onActive($event)">Настройки</div>
      </div>
      <div class="offers__category block">
        <div class="category__img"></div>
        <div class="category__desc">
          <div class="title text-base">Серьезные отношения</div>
          <div class="text text-base">Ваша категория</div>
        </div>
      </div>
      <div class="offers__rest block" @click="this.$router.push('/restaurants')">
        <div class="rest__img"></div>
        <div class="rest__desc">
          <div class="title text-base">Рестораны рядом</div>
          <div class="text text-base">
            Найдите рестораны и бары поблизости и бронируйте столики
          </div>
        </div>
      </div>
      <div class="offers__abilities block">
        <div class="abil">
          <div class="abil__img"></div>
          <div class="abil__desc">
            <div class="title text-base">Diamonds Premium</div>
            <div class="text text-base">
              Пишите первым всем, кому захотите в приобретенной категории
            </div>
          </div>
          <button-base
            class="abil__button"
            @click="this.$router.push('/myaccountsubscribe')"
          >
            Узнать подробнее
          </button-base>
        </div>
      </div>
    </div>
    <app-menu></app-menu>
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu.vue";
import ButtonBase from "../components/UI/ButtonBase.vue";

export default {
  name: "OffersOrSettings",
  components: {
    ButtonBase,
    AppMenu,
  },
  data() {
    return {
      isModalShown: false,
      category: "",
      active: false,
    };
  },
  methods: {
    onActive(e) {
      let navs = document.querySelectorAll('.navs__item')
      navs.forEach(item => {
        item.classList.remove('active')
      })
      e.target.classList.toggle('active')
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  display: flex;
  flex-direction: column;
  padding: 7vh 4% 6vh;

  .title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 9px;
  }

  .text {
    font-size: 16px;
    color: #393939;
  }

  &__bio {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 32px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6%;
  }

  &__content {
    text-align: left;
    flex-grow: 2;
    margin: 0 auto;

    .text-base {
      &.head {
        margin-bottom: 7px;
        color: var(--color-secondary);
      }
    }
  }

  .block {
    background: var(--color-white);
    box-shadow: 0px 4px 24px rgba(78, 60, 33, 0.08);
    border-radius: 12px;
    padding: 24px 16px 22px;
    margin-bottom: 12px;
  }

  &__navs {
    display: flex;
    justify-content: space-between;

    &.block {
      background: var(--color-light-10);
      padding: 2px;
    }
  }

  .navs {
    &__item {
      padding: 12px 40px;

      &.active {
        border-radius: 10px;
        background: var(--color-white);
      }
    }
  }

  &__category {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .category {
      &__img {
        background: no-repeat
          url("../assets/img/offers_or_settings/Gategory.png");
        width: 40px;
        min-height: 40px;
      }
    }
  }

  &__abilities {
    .abil {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;

      &__img {
        width: 56px;
        height: 56px;
        background: no-repeat center center
          url("../assets/img/myaccount/DiamondsPremium.png");
        margin-bottom: 19px;
      }

      &__desc {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      &__button {
        background: var(--color-light-10);
        color: var(--color-main-dark);
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.32px;
      }

      .text {
        margin-bottom: 21px;
      }
    }
  }

  &__rest {
    position: relative;
    width: 100%;

    .rest {
      &__img {
        position: absolute;
        background: no-repeat url("../assets/img/offers_or_settings/Rest.png");
        width: 67%;
        min-height: 100%;
        right: 0;
        bottom: 0;
        background-size: cover;
      }

      &__desc {
        width: 50%;
      }
    }
  }

  .line {
    border-bottom: 1px solid #f2f1f0;
  }
}
</style>

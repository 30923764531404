<template>
  <div class="modal" :class="{ relative: begin }">
    <div class="modal__overlay" :class="{ relative: begin }"></div>
    <div
      class="modal__window"
      :class="{ modal__window_fullscreen: fullScreen, relative: begin }"
    >
      <div
        class="modal__close close"
        :class="{ hide: !header, no_close: !close }"
        @click="onClose"
      ></div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalBase",
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    begin: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  &.relative {
    position: relative;
  }

  &__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: var(--color-main-dark);
    opacity: 0.3;

    &.relative {
      position: relative;
    }
  }

  &__window {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 95%;
    background: var(--color-light-0);
    border-radius: 12px 12px 0 0;
    z-index: 10;
    overflow: hidden;

    &.relative {
      position: relative;
    }

    &_fullscreen {
      height: 100%;
    }
  }

  &__close {
    z-index: 100;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 50%;
    background: no-repeat center center var(--color-light-10)
      url("../../assets/img/Close-light.svg");

    &.no_close {
      display: none;
    }
  }
}

.hide {
  display: none;
}
</style>
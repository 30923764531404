<template>
  <div class="edit_question page">
    <header class="edit_question__header">
      <div class="back"></div>
      <h1 class="title">Main information</h1>
      <div></div>
    </header>
    <modal-who-i-am></modal-who-i-am>
  </div>
</template>
<script>
import ModalWhoIAm from "../components/ModalWhoAmI.vue"

export default {
  name: 'MyAccountEditQuestionAnswer',
  components: {
    ModalWhoIAm
  }
}
</script>
<style lang="scss" scoped>
.edit_question {
  padding: 7vh 0 6vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-left: 4.8%;
    padding-right: 5.3%;
    padding-bottom: 10px;
    margin-bottom: 24px;
    align-items: center;
    box-shadow: 0px 1px 0px var(--color-divider);

    .text-base {
      font-size: 16px;
      color: #d89972;
    }
  }

  .clear {
    margin-top: 1px;
  }

  &__title {
    padding: 0 4%;
    color: var(--color-secondary);
  }
}
</style>
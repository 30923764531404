<template>
  <modal-base>
    <header class="header">
      <h2 class="title">Send request</h2>
      <div class="close" @click="this.$emit('close', false)"></div>
    </header>
    <div class="reveal">
      <div class="reveal__content">
        <br />
        <div class="reveal__main">
          <div class="reveal__img">
            <img src="../assets/img/profileView/BlurMini.png" />
          </div>
          <div class="reveal__desc text-base">
            <div class="reveal__title">Reveal photos</div>
            <div class="reveal__text text-base">
              You are interested in a person and you want to see his photo, send
              a request and if the user approves him, the photo will be
              available for viewing.
            </div>
          </div>
        </div>
      </div>
      <button-base class="reveal__button"> Send a request </button-base>
    </div>
  </modal-base>
</template>
<script>
import ModalBase from "./UI/ModalBase.vue";
import ButtonBase from "../components/UI/ButtonBase.vue";

export default {
  name: "ModalRequestPhoto",
  components: {
    ModalBase,
    ButtonBase
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .title {
    font-size: 20px;
    line-height: 24px;
  }
}

.close {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: no-repeat center center var(--color-light-10)
    url("../assets/img/Close-light.svg");
}

.reveal {
  display: flex;
  flex-direction: column;
  padding: 7vh 4% 6vh;
  height: calc(100vh - 87px);

  &__content {
    text-align: left;
    flex-grow: 2;
    margin: 0 auto;
  }

  .line {
    border-bottom: 1px solid #f2f1f0;
  }

  &__img {
    margin-bottom: 19px;

    img {
      width: 128px;
      height: 128px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 25px;
    border-radius: 12px;
    padding: 21px 16px 26px;
    position: relative;
  }

  &__desc {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 9px;
  }

  &__text {
    font-size: 16px;
    color: #393939;
    margin-bottom: 21px;
  }

  &__button {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;

    &:active {
      opacity: .5;
    }
  }
}
</style>
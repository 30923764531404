<template>
  <div class="subscribe page">
    <header class="subscribe__header">
      <div class="back" @click="this.$router.push('/myaccount')"></div>
    </header>
    <div class="subscribe__content">
      <div class="title">NID Premium</div>
      <br />
      <div class="functions">
        <div class="functions__img">
          <img src="../assets/img/myaccount/NIDPremium.png" />
        </div>
        <div class="functions__desc text-base hide" v-for="item of hintItems" :key="item.hintNum" :class="{'show': hintNum === item.hintNum}">
          <div class="functions__title">{{ item.title }}</div>
          <div class="functions__text text-base">
            {{ item.text }}
          </div>
        </div>
        <div class="functions__dots">
          <div class="dot" :class="{'active': hintNum === 1}"></div>
          <div class="dot" :class="{'active': hintNum === 2}"></div>
          <div class="dot" :class="{'active': hintNum === 3}"></div>
        </div>
      </div>
      <div class="line"></div>
      <div class="subscribe__category">
        <p class="text-base head">Категория Premium</p>
        <select-base
          :label="false"
          placeholder="Category"
          :selected="category"
          @click="isModalShown = true"
        >
        </select-base>
      </div>
      <modal-welcome v-if="isModalShown" @select="onSelect($event)">
      </modal-welcome>
      <div class="subscribe__tariff">
        <p class="text-base head">Выберите тариф</p>
        <div class="tariff__items">
          <div
            class="tariff"
            v-for="(item, idx) in tariffs"
            :key="idx"
            :class="{ active: item.active === true }"
            @click="onTariff(idx)"
          >
            <div class="tariff__hit text-base" v-show="item.hit === true">
              ХИТ
            </div>
            <div class="tariff__desc text-base">
              <div class="tariff__monthes text-base">{{ item.monthes }}</div>
              <div class="tariff__every-month text-base">
                {{ item.every_month }} ₽/мес
              </div>
              <div class="tariff__total text-base">{{ item.total }} ₽</div>
            </div>
          </div>
        </div>
      </div>
      <button-base class="subscribe__button" @click="this.$router.push('#')">
        Продолжить
      </button-base>
    </div>
  </div>
</template>

<script>
import ButtonBase from "../components/UI/ButtonBase.vue";
import SelectBase from "../components/UI/SelectBase.vue";
import ModalWelcome from "../components/ModalWelcome.vue";

export default {
  name: "MyAccountSubscribeFourVars",
  components: {
    ButtonBase,
    SelectBase,
    ModalWelcome,
  },
  data() {
    return {
      isModalShown: false,
      category: "",
      tariffs: [
        {
          id: 1,
          monthes: "1 месяц",
          every_month: "649,00",
          total: "329,00",
          hit: true,
          active: true,
        },
        {
          id: 2,
          monthes: "3 месяца",
          every_month: "649,00",
          total: "649,00",
          hit: false,
          active: false,
        },
        {
          id: 3,
          monthes: "6 месяцев",
          every_month: "649,00",
          total: "2 390,00",
          hit: false,
          active: false,
        },
        {
          id: 4,
          monthes: "1 год",
          every_month: "649,00",
          total: "5 490,00",
          hit: false,
          active: false,
        },
      ],
      hintItems: [
        {
          title: "Пишите всем",
          text: "Пишите первым всем, кому захотите в приобретенной категории",
          hintNum: 1,
        },
        {
          title: "Premium functions",
          text: "Write to everyone first in the selected category",
          hintNum: 2,
        },
        {
          title: "Пишите всем",
          text: "Write to everyone first in the selected category",
          hintNum: 3,
        },
      ],
      hintNum: 1,
      x1: null,
      y1: null,
    };
  },
  methods: {
    onSelect(e) {
      this.category = e.selected;
      this.isModalShown = false;
    },

    onTariff(idx) {
      this.tariffs = this.tariffs.map((tariff) => ({
        ...tariff,
        active: false,
      }));
      this.tariffs[idx].active = true;
    },

    handleTouchStart(e) {
      const ft = e.touches[0];
      this.x1 = ft.clientX;
      this.y1 = ft.clientY;
      console.log(this.x1)
    },

    handleTouchMove(e) {
      const move = e.touches[0];
      if (!this.x1 || !this.y1) {
        return false;
      }

      let x2 = move.clientX;
      let y2 = move.clientY;

      let xDiff = x2 - this.x1;
      let yDiff = y2 - this.y1;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        //r - l
        if (xDiff > 0 && this.hintNum < 3) {
          this.hintNum += 1;
        }

        if (xDiff < 0 && this.hintNum > 1) {
          this.hintNum -= 1;
        }
      } 
      this.x1 = null;
      this.y1 = null;
    },
  },

  mounted() {
    const area = document.querySelector(".functions");
    if (area) {
      area.addEventListener("touchstart", this.handleTouchStart, false);
      area.addEventListener("touchmove", this.handleTouchMove, false);
    }
  },

  unmounted() {
    const area = document.querySelector(".functions");
    if (area) {
      area.removeEventListener("touchstart", this.handleTouchStart, false);
      area.removeEventListener("touchmove", this.handleTouchMove, false);
    }
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  display: flex;
  flex-direction: column;
  padding: 7vh 4% 6vh;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6%;
  }

  &__button {
    margin: 38px 0 50px;

    &:active {
      opacity: 0.5;
    }
  }

  &__content {
    text-align: left;
    flex-grow: 2;
    margin: 0 auto;

    .title {
      font-size: 32px;
    }

    .text-base {
      &.head {
        margin-bottom: 7px;
        color: var(--color-secondary);
      }
    }
  }

  &__category {
    margin: 26px 0 27px;
  }

  .line {
    border-bottom: 1px solid #f2f1f0;
  }
}

.functions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 25px;
  background: var(--color-white);
  box-shadow: 0px 4px 24px rgba(78, 60, 33, 0.08);
  border-radius: 12px;
  padding: 21px 16px 26px;

  &__img {
    margin-bottom: 19px;

    img {
      width: 56px;
      height: 56px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 9px;
  }

  &__text {
    font-size: 16px;
    color: #393939;
    margin-bottom: 21px;
  }

  &__button {
    background: var(--color-light-10);
    color: var(--color-main-dark);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  &__dots {
    display: flex;

    .dot {
      width: 7px;
      height: 7px;
      background: #e6e4e3;
      border-radius: 50%;
      margin-right: 8px;

      &.active {
        background: linear-gradient(0deg, #e3b687, #e3b687), #d89972;
      }
    }
  }
}

.tariff__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 17px;
}

.tariff {
  position: relative;
  background: transparent;
  border-radius: 12px;
  border: 1px solid var(--color-secondary);
  padding: 20px 0 16px;
  text-align: center;
  width: 48%;
  margin-bottom: 13px;
  opacity: 0.5;

  &__every-month {
    font-weight: 400;
    font-size: 12px;
    margin: 4px 0 12px;
  }

  &__total {
    font-size: 18px;
  }

  &__hit {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    padding: 2px 16px 0;
    border-radius: 76px;
    background: var(--color-divider);
    color: var(--color-white);
    font-size: 11px;
  }

  &.active {
    background: #faf2ec;
    border: 1px solid var(--color-main-extra-light);
    opacity: 1;

    .tariff__hit {
      background: linear-gradient(
          0deg,
          var(--color-main-extra-light),
          var(--color-main-extra-light)
        ),
        var(--color-main-light);
    }
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}
</style>

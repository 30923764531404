<template>
  <div class="main">
    <div class="back"></div>
    <div class="nameUser">
      Kate, 21
      <div class="status"><div class="tree"></div>typing</div>
    </div>
    <div class="sear"></div>
  </div>
  <div class="contentChat">
    <div class="die">
      <div class="dieContent">
        Hi! How are you? <div class="time">16:35<div class="commas"></div></div>
      </div>
    </div><br>
    <div class="dieLeft">
      <div class="dieContent">
        Hello, I am fine! <div class="time">16:35<div class="commas"></div></div>
      </div>
    </div><br>
    <div class="dieHome">
      <div class="dieContent">
        I’m a John from New York City!
        Here is my photo <div class="time">16:35<div class="commas"></div></div>
      </div>
    </div>
    <div class="photoMen"></div>
    <br>
  </div>
  <div v-if="show" class="modal-shadow" @click.self="closeModal">

    <div class="modal">
      <div class="modal__content">Attach a photo</div>
      <div class="line"></div>
      <div class="modal__content">Invite on a date</div>
      <div class="line"></div>
      <div class="modal__content">Request a video</div>
      <div class="line"></div>
      <div class="modal__content">Send a video</div>
      <slot name="footer">
        <div class="modal-footer">
          <button class="buttonPatternOne">Cancel</button>
        </div>
      </slot>
    </div>

  </div>

</template>

<script>





export default {
  name: "PatternOneModal",
  components: {},
  data: function () {
    return {
      show: true,

    }
  },
  methods: {
    closeModal () {
      this.show = false
    },
    buttonReplacement() {
      this.iconButtonApple="false"
      console.log("dsfgsdfgsdfg")
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped >
.body {
  display: flex;
  color: black;
}

.buttonPatternOne {
  width: 100%;
  height: 60px;
  margin-left: -15px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.38px;
  border: 0;
  background-color: white;
  border-radius: 14px;
  margin-top: 20px;
  position: absolute;


}
.dieLeft {
  width: 255px;
  height: 35px;
  margin-left: 1%;
  background: #F2F1F0;
  border-radius: 16px 16px 6px 16px;

  .dieContent {
    width: 90%;
    height: 90%;
    margin-left: 10px;
    padding-top: 5px;
    justify-content: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #362614;

    .time {
      font-size: 11px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.078px;
      color: #362614;
      margin-top: -10px;
    }
    .commas {
      width: 10px;
      height: 10px;
      background: no-repeat center center url('../assets/img/profileView/Group107.png');
      margin-top: -14px;
      margin-left: 232px;

    }
  }
}
.photoMen {
  background-size: contain;
  width: 239px;
  height: 253px;
  margin-left: 36%;
  background: no-repeat center center url('../assets/img/profileView/RectangleMan.svg');

}
.dieHome {
  width: 255px;
  height: 55px;
  margin-left: 30%;
  background: linear-gradient(0deg, #F2E0D0, #F2E0D0), #F2E0D0;
  border-radius: 16px 16px 6px 16px;

  .dieContent {
    width: 90%;
    height: 90%;
    margin-left: 10px;
    padding-top: 5px;
    justify-content: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #362614;

    .time {
      font-size: 11px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.078px;
      color: #362614;
      margin-top: -10px;
    }
    .commas {
      width: 10px;
      height: 10px;
      background: no-repeat center center url('../assets/img/profileView/Group107.png');
      margin-top: -14px;
      margin-left: 232px;

    }

  }

}
.die {
  width: 255px;
  height: 35px;
  margin-left: 30%;
  background: linear-gradient(0deg, #F2E0D0, #F2E0D0), #F2E0D0;
  border-radius: 16px 16px 6px 16px;


  .dieContent {
    width: 90%;
    height: 90%;
    margin-left: 10px;
    padding-top: 5px;
    justify-content: center;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: #362614;

    .time {
      font-size: 11px;
      line-height: 16px;
      text-align: right;
      letter-spacing: -0.078px;
      color: #362614;
      margin-top: -10px;
    }
    .commas {
      width: 10px;
      height: 10px;
      background: no-repeat center center url('../assets/img/profileView/Group107.png');
      margin-top: -14px;
      margin-left: 232px;
    }
  }
}
.contentChat {
  min-height: calc(80vh - 80px);
}



.sear {
  background: no-repeat right center url('../assets/img/profileView/kate.png');
  background-size: contain;
  width: 43px;
  height: 43px;

}
.nameUser {
  width: 90%;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #362614;
}
.main {
  display: flex;
  width: 100vw;
  padding: 35px 15px 15px 15px;
  background: #FCFCFC;

  .status {
    display: flex;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.078px;
    color: #D89972;
    margin-left: 40%;

    .tree {
      width: 32px;
      height: 14px;
      background: no-repeat center center url('../assets/img/profileView/Group182.png');
      text-align: center;
    }
  }
}
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999999;

  .textCenter {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #362614;
    min-height: 70%;
    display: grid;
    align-items: flex-end;
  }

  .modal {
    background: #F2F0ED;
    backdrop-filter: blur(54.3656px);
    border-radius: 14px;
    padding: 15px;
    min-width: 95%;
    max-width: 100%;

    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, -91%);

    .modal__content {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.408px;
      color: #393939;
      display: flex;
      color: black;
      margin-left: 35%;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .line {
      border-bottom: 1px solid #e6e4e3;
    }

    .textButtonDown {
      font-family: 'Helvetica Neue';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.32px;
      color: #979491;
      top: 20px;
    }



    &-close {
      border-radius: 50%;
      color: #979491;
      background: #F2F1F0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 7px;
      right: 7px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    &-title {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding: 0 0 30px 10px;
      letter-spacing: 0.38px;
      color: #362614;

    }

    &-content {
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      padding: 17px;
      letter-spacing: -0.32px;
      color: #362614;
      margin-left: 33px;

    }

    &-footer {
      &__button {
        background-color: #0971c7;
        color: #fff;
        border: none;
        text-align: center;
        padding: 8px;
        font-size: 17px;
        font-weight: 500;
        border-radius: 8px;
        min-width: 150px;
      }
    }
  }
}


.appleLogo {
  position: absolute;
  z-index: 99999999;
  width: 41px;
  height: 49px;
  background: no-repeat center center url('../assets/img/auth/Apple-ID.svg');
  background-size: 25px;
  margin-top: -15px;
  margin-left: -51px;
}

.appleLogoF {
  position: absolute;
  z-index: 99999999;
  width: 41px;
  height: 49px;
  background: no-repeat center center url('../assets/img/auth/Facebook.svg');
  background-size: 25px;
  margin-top: -15px;
  margin-left: -51px;
}
.appleLogoM {
  position: absolute;
  z-index: 99999999;
  width: 41px;
  height: 49px;
  background: no-repeat center center url('../assets/img/auth/Mail.svg');
  background-size: 25px;
  margin-top: -15px;
  margin-left: -51px;
}
.appleLogoP {
  position: absolute;
  z-index: 99999999;
  width: 41px;
  height: 49px;
  background: no-repeat center center url('../assets/img/auth/Phone.svg');
  background-size: 25px;
  margin-top: -15px;
  margin-left: -51px;
}
.appleLogoR {
  position: absolute;
  z-index: 99999999;
  width: 41px;
  height: 49px;
  background: no-repeat center center url('../assets/img/auth/Register.svg');
  background-size: 25px;
  margin-top: -15px;
  margin-left: -51px;
}

.line {
  border-bottom: 1px solid #F2F1F0; /* Параметры линии */
}

</style>
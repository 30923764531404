<template>
  <div class="button" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ButtonBase",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },

    colorBackground: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  methods: {
    click() {
      this.$emit("click");
    },
  },
  computed: {
    className() {
      return {
        disabled: this.disabled,
        ButtonBase__error: this.error,
        ButtonBase__success: this.success,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
      0deg,
      var(--color-main-extra-light),
      var(--color-main-extra-light)
    ),
    var(--color-main-light);
  border-radius: 12px;
  padding: 13px 0;
  cursor: pointer;
  font-family: var(--font-medium);
  color: var(--color-white);

  &.disabled {
    pointer-events: none;
    background: var(--color-main-extra-light);
    opacity: 0.5;
  }

  &__error {
    background: lightcoral;
  }

  &__success {
    background: green;
    opacity: 0.6;
  }

  &:active {
    opacity: 0.5;
  }
}
</style>

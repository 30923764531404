<template>
  <div class="star">
    <svg v-show="!yellow"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 10.5134L11.1202 13L10.0268 8.31337L13.6668 5.16004L8.8735 4.75337L7.00016 0.333374L5.12683 4.75337L0.333496 5.16004L3.9735 8.31337L2.88016 13L7.00016 10.5134Z"
        fill="#C9C6BF"
      />
    </svg>
    <svg v-show="yellow"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 10.5134L11.1202 13L10.0268 8.31337L13.6668 5.16004L8.8735 4.75337L7.00016 0.333374L5.12683 4.75337L0.333496 5.16004L3.9735 8.31337L2.88016 13L7.00016 10.5134Z"
        fill="#F2C934"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "RatingStar",
  props: {
    yellow: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.star {
  .yellow svg path {
    fill: #f2c934;
  }
}
</style>

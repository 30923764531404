import { createRouter, createWebHistory } from 'vue-router'
import StartView from '../views/StartView.vue'
import TestView from '../views/TestView.vue'
import AuthView from '../views/AuthView.vue'
import AuthViewG from '../views/AuthViewG.vue'
import PeopleSearch from '../views/PeopleSearch.vue'
import PeopleSearchFilter from '../views/PeopleSearchFilter.vue'
import RegisterWelcome from '../views/RegisterWelcome.vue'
import RegisterSelectGender from '../views/RegisterSelectGender.vue'
import RegisterEnterName from '../views/RegisterEnterName.vue'
import RegisterBirthday from '../views/RegisterBirthday.vue'
import RegisterEmail from '../views/RegisterEmail.vue'
import RegisterCellphone from '../views/RegisterCellphone.vue'
import RegisterConfirmNumber from '../views/RegisterConfirmNumber.vue'
import RegisterEnterPassword from '../views/RegisterEnterPassword.vue'
import RegisterAddPhotos from '../views/RegisterAddPhotos.vue'
import FirstStartHints from '../views/FirstStartHints.vue'
import LoginByPhone from '../views/LoginByPhone.vue'
import LoginByEmail from "../views/LoginByEmail";
import RegisterAddedPhotos from '../views/RegisterAddedPhotos.vue'
import RegisterPresentationVideo from '../views/RegisterPresentationVideo.vue'
import FeedView from '../views/FeedView.vue'
import ProfileView from "../views/ProfileView.vue";
import ChatDates from "@/views/ChatDates";
import ChatMatches from "@/views/ChatMatches";
import ChatMessages from "@/views/ChatMessages";
import MyAccount from "../views/MyAccount.vue"
import MyAccountSubscribe from "../views/MyAccountSubscribe.vue"
import MyAccountSubscribeFourVars from "../views/MyAccountSubscribeFourVars.vue"
import MyAccountEdit from "../views/MyAccountEdit.vue"
import MyAccountEditMainInfo from "../views/MyAccountEditMainInfo.vue"
import MyAccountEditJobAndEducation from "../views/MyAccountEditJobAndEducation.vue"
import MyAccountEditPersonalInformation from "../views/MyAccountEditPersonalInformation.vue"
import MyAccountInstagram from "../views/MyAccountInstagram.vue"
import ChatFirstRun from '../views/ChatFirstRun.vue'
import ChatSingleStart from '../views/ChatSingleStart.vue'
import MyAccountSettings from "../views/MyAccountSettings.vue"
import MyAccountDelete from "../views/MyAccountDelete.vue"
import MyAccountReasonDeletion from "../views/MyAccountReasonDeletion.vue"
import MyAccountConfirmDelete from "../views/MyAccountConfirmDelete.vue"
import MyAccountDeleteFreeze from "../views/MyAccountDeleteFreeze.vue"
import MyAccountQuestions from "../views/MyAccountQuestions.vue"
import MyAccountAddQuestions from "../views/MyAccountAddQuestions.vue"
import MyAccountAddQuestion from "../views/MyAccountAddQuestion.vue"
import MyAccountAddQuestionsAnswer from "../views/MyAccountAddQuestionsAnswer.vue"
import MyAccountEditQuestionAnswer from "../views/MyAccountEditQuestionAnswer.vue"
import MyAccountLogout from "../views/MyAccountLogout.vue"
import ProfileViewBlur from "../views/ProfileViewBlur.vue"
import ProfileViewBlurComplain from "../views/ProfileViewBlurComplain.vue"
import MyAccountTags from "../views/MyAccountTags.vue"
import MyAccountSelectCountry from "../views/MyAccountSelectCountry.vue"
import SelectPurpose from "../views/SelectPurpose"
import PatternOne from "../views/PatternOne.vue"
import PatternTwo from "../views/PatternTwo.vue"
import PatterThree from "../views/PatternThree.vue"
import PatternOneAccept from "../views/PatternOneAccept.vue"
import PatternTwoDecl from "../views/PatternTwoDecl.vue"
import PatternToday from "../views/PatternToday.vue"
import PatternOneModal from "../views/PatternOneModal.vue"
import OffersOrSettings from "../views/OffersOrSettings.vue"
import PeopleSearchRest from "../views/PeopleSearchRest.vue"
import Restaurants from "../views/Restaurants.vue"
import RestaurantsOnCardWithModal from "../views/RestaurantsOnCardWithModal.vue"
import RestaurantsOnCardBeginModal from "../views/RestaurantsOnCardBeginModal.vue"
import RestaurantsOnCardMiddleModal from "../views/RestaurantsOnCardMiddleModal.vue"
import ChatSelected from "../views/ChatSelected.vue"
import ChatSwipe from "../views/ChatSwipe.vue"
import LoginByEmailError from "../views/LoginByEmailError.vue"
import LoginByPhoneError from "../views/LoginByPhoneError.vue"
import ProfileViewBlurComplainSent from "../views/ProfileViewBlurComplainSent.vue"
import TermsUse from "../views/TermsUse.vue"


const routes = [
  {
    path: '/',
    name: 'StartView',
    component: StartView
  },
  {
    path: '/test',
    name: 'TestView',
    component: TestView
  },
  {
    path: '/auth',
    name: 'AuthView',
    component: AuthView
  },
  {
    path: '/authG',
    name: 'AuthViewG',
    component: AuthViewG
  },
  {
    path: '/LoginByPhone',
    name: 'LoginByPhone',
    component: LoginByPhone
  },
  {
    path: '/LoginByEmail',
    name: 'LoginByEmail',
    component: LoginByEmail
  },
  {
    path: '/LoginByEmailError',
    name: 'LoginByEmailError',
    component: LoginByEmailError
  },
  {
    path: '/LoginByPhoneError',
    name: 'LoginByPhoneError',
    component: LoginByPhoneError
  },
  {
    path: '/search',
    name: 'PeopleSearch',
    component: PeopleSearch
  },
  {
    path: '/filter',
    name: 'PeopleSearchFilter',
    component: PeopleSearchFilter
  },
  {
    path: '/register/welcome',
    name: 'RegisterWelcome',
    component: RegisterWelcome
  },
  {
    path: '/register/gender',
    name: 'RegisterSelectGender',
    component: RegisterSelectGender
  },
  {
    path: '/register/name',
    name: 'RegisterEnterName',
    component: RegisterEnterName
  },
  {
    path: '/register/birthday',
    name: 'RegisterBirthday',
    component: RegisterBirthday
  },
  {
    path: '/register/email',
    name: 'RegisterEmail',
    component: RegisterEmail
  },
  {
    path: '/register/cellphone',
    name: 'RegisterCellphone',
    component: RegisterCellphone
  },
  {
    path: '/register/confirmnumber',
    name: 'RegisterConfirmNumber',
    component: RegisterConfirmNumber
  },
  {
    path: '/register/password',
    name: 'RegisterEnterPassword',
    component: RegisterEnterPassword
  },
  {
    path: '/register/addphotos',
    name: 'RegisterAddPhotos',
    component: RegisterAddPhotos
  },
  {
    path: '/hints',
    name: 'FirstStartHints',
    component: FirstStartHints
  },
  {
    path: '/register/addedphotos',
    name: 'RegisterAddedPhotos',
    component: RegisterAddedPhotos
  },
  {
    path: '/register/presentationvideo',
    name: 'RegisterPresentationVideo',
    component: RegisterPresentationVideo
  },
  {
    path: '/profileview',
    name: 'ProfileView',
    component: ProfileView
  },
  {
    path: '/feed',
    name: 'FeedView',
    component: FeedView
  },
  {
    path: '/ChatMessages',
    name: 'ChatMessages',
    component: ChatMessages
  },
  {
    path: '/ChatMatches',
    name: 'ChatMatches',
    component: ChatMatches
  },
  {
    path: '/ChatDates',
    name: 'ChatDates',
    component: ChatDates
  },
  {
    path: '/myaccount',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/myaccountsubscribe',
    name: 'MyAccountSubscribe',
    component: MyAccountSubscribe
  },
  {
    path: '/myaccountsubscribefourvars',
    name: 'MyAccountSubscribeFourVars',
    component: MyAccountSubscribeFourVars
  },
  {
    path: '/myaccountedit',
    name: 'MyAccountEdit',
    component: MyAccountEdit
  },
  {
    path: '/myaccounteditmaininfo',
    name: 'MyAccountEditMainInfo',
    component: MyAccountEditMainInfo
  },
  {
    path: '/myaccounteditjobandeducation',
    name: 'MyAccountEditJobAndEducation',
    component: MyAccountEditJobAndEducation
  },
  {
    path: '/myaccounteditpersonalinformation',
    name: 'MyAccountEditPersonalInformation',
    component: MyAccountEditPersonalInformation
  },
  {
    path: '/myaccountinstagram',
    name: 'MyAccountInstagram',
    component: MyAccountInstagram
  },
  {

    path: '/ChatFirstRun',
    name: 'ChatFirstRun',
    component: ChatFirstRun
  },
  {
    path: '/ChatSingleStart',
    name: 'ChatSingleStart',
    component: ChatSingleStart
  },
  {
    path: '/myaccountsettings',
    name: 'MyAccountSettings',
    component: MyAccountSettings
  },
  {
    path: '/myaccountlogout',
    name: 'MyAccountLogout',
    component: MyAccountLogout
  },
  {
    path: '/myaccountdelete',
    name: 'MyAccountDelete',
    component: MyAccountDelete
  },
  {
    path: '/myaccountreasondeletion',
    name: 'MyAccountReasonDeletion',
    component: MyAccountReasonDeletion
  },
  {
    path: '/myaccountconfirmdelete',
    name: 'MyAccountConfirmDelete',
    component: MyAccountConfirmDelete
  },
  {
    path: '/myaccountdeletefreeze',
    name: 'MyAccountDeleteFreeze',
    component: MyAccountDeleteFreeze
  },
  {
    path: '/myaccountquestions',
    name: 'MyAccountQuestions',
    component: MyAccountQuestions
  },
  {
    path: '/myaccountaddquestions',
    name: 'MyAccountAddQuestions',
    component: MyAccountAddQuestions
  },
  {
    path: '/myaccountaddquestion',
    name: 'MyAccountAddQuestion',
    component: MyAccountAddQuestion
  },
  {
    path: '/myaccountaddquestionsanswer',
    name: 'MyAccountAddQuestionsAnswer',
    component: MyAccountAddQuestionsAnswer
  },
  {
    path: '/myaccounteditquestionanswer',
    name: 'MyAccountEditQuestionAnswer',
    component: MyAccountEditQuestionAnswer
  }, 
  {
    path: '/profileviewblur',
    name: 'ProfileViewBlur',
    component: ProfileViewBlur
  },
  {
    path: '/profileviewblurcomplain',
    name: 'ProfileViewBlurcomplain',
    component: ProfileViewBlurComplain
  },
  {
    path: '/profileviewblurcomplainsent',
    name: 'ProfileViewBlurcomplainSent',
    component: ProfileViewBlurComplainSent
  },
  {
    path: '/myaccounttags',
    name: 'MyAccountTags',
    component: MyAccountTags
  },
  {
    path: '/myaccountselectcountry',
    name: 'MyAccountSelectCountry',
    component: MyAccountSelectCountry
  },
  {
    path: '/selectpurpose',
    name: 'SelectPurpose',
    component: SelectPurpose
  },
  {
    path: '/patternOne',
    name: 'PatternOne',
    component: PatternOne
  },
  {
    path: '/patternTwo',
    name: 'PatternTwo',
    component: PatternTwo
  },
  {
    path: '/patterThree',
    name: 'PatterThree',
    component: PatterThree
  },
  {
    path: '/patternOneAccept',
    name: 'PatternOneAccept',
    component: PatternOneAccept
  },
  {
    path: '/patternTwoDecl',
    name: 'PatternTwoDecl',
    component: PatternTwoDecl
  },
  {
    path: '/patternToday',
    name: 'PatternToday',
    component: PatternToday
  },
  {
    path: '/patternOneModal',
    name: 'PatternOneModal',
    component: PatternOneModal,
  },
  {
    path: '/offersorsettings',
    name: 'OffersOrSettings',
    component: OffersOrSettings
  },
  {
    path: '/searchrest',
    name: 'PeopleSearchRest',
    component: PeopleSearchRest
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: Restaurants
  },
  {
    path: '/restaurantsoncardwithmodal',
    name: 'RestaurantsOnCardWithModal',
    component: RestaurantsOnCardWithModal
  },
  {
    path: '/restaurantsoncardbeginmodal',
    name: 'RestaurantsOnCardBeginModal',
    component: RestaurantsOnCardBeginModal
  },
  {
    path: '/chatSelected',
    name: 'ChatSelected',
    component: ChatSelected
  },
  {
    path: '/restaurantsoncardmiddlemodal',
    name: 'RestaurantsOnCardMiddleModal',
    component: RestaurantsOnCardMiddleModal
  },
  {
    path: '/chatSwipe',
    name: 'ChatSwipe',
    component: ChatSwipe
  },
  {
    path: '/terms',
    name: 'TermsUse',
    component: TermsUse
  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<template>
  <div class="search page">
    <header class="search__header">
      <div class="back"></div>
      <div class="srch" @click="this.$router.push('#')"></div>
    </header>
    <div class="search__content">
      <h1 class="title">Рестораны</h1>
      <!-- scroll start -->
      <div class="scroll-dating">
        <div class="scroll-dating__item">
          <div class="scroll-dating__header">
            <div class="scroll-dating__img">
              <img src="../assets/img/photos/Lisa_min.jpg" />
            </div>
            <div class="scroll-dating__bio title">
              <span class="scroll-dating__name">Лиза</span>
              <span>, </span>
              <span class="scroll-dating__age">21</span>
            </div>
            <div class="scroll-dating__confirmed">
              <div class="confirm"></div>
            </div>
          </div>
          <div class="scroll-dating__content text-base">
            Ростов! Кто сейчас в центре, пошли на обе...
          </div>
        </div>
        <div class="scroll-dating__item">
          <div class="scroll-dating__header">
            <div class="scroll-dating__img">
              <img src="../assets/img/photos/Konstantin_mini.jpg" />
            </div>
            <div class="scroll-dating__bio title">
              <span class="scroll-dating__name">Константин</span>
              <span>, </span>
              <span class="scroll-dating__age">43</span>
            </div>
            <div class="scroll-dating__confirmed">
              <div class="confirm"></div>
            </div>
          </div>
          <div class="scroll-dating__content text-base">
            Приглашу милую девушку на свидание...
          </div>
        </div>
      </div>
      <!-- scroll finish -->

      <div class="line"></div>
      <div class="search__carousel">
        <div class="head">
          <div class="title">Рестораны</div>
          <div class="to_rest"></div>
        </div>
        <div class="rest_list">
          <div class="rest_list__item" v-for="item of rest_list" :key="item.id">
            <div class="rest_list__photo">
              <img :src="item.img" />
              <div class="rest_list__distance text-base">
                {{ item.distance }} м
              </div>
            </div>
            <div class="rest_list__bio">
              <span class="rest_list__name text-base">{{ item.name }}</span>
              <span class="rest_list__age"
                >{{ item.persons_now }} человека сейчас</span
              >
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="head">
          <div class="title">Кафе и бары</div>
          <div class="to_rest"></div>
        </div>
        <div class="rest_list">
          <div class="rest_list__item" v-for="item of cafe_list" :key="item.id">
            <div class="rest_list__photo">
              <img :src="item.img" />
              <div class="rest_list__distance text-base">
                {{ item.distance }} м
              </div>
            </div>
            <div class="rest_list__bio">
              <span class="rest_list__name text-base">{{ item.name }}</span>
              <span class="rest_list__age"
                >{{ item.persons_now }} человека сейчас</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-menu></app-menu>
  </div>
</template>
<script>
import AppMenu from "../components/AppMenu.vue";

export default {
  name: "PeopleSearchRest",
  components: {
    AppMenu,
  },
  data() {
    return {
      rest_list: [
        {
          id: 1,
          name: "Fire Lake Grill House & Cocktail Bar",
          persons_now: 2,
          img: require("../assets/img/restaurant/Rest.png"),
          distance: 500,
        },
        {
          id: 2,
          name: "Fire Lake Grill House & Cocktail Bar",
          persons_now: 2,
          img: require("../assets/img/restaurant/Rest.png"),
          distance: 500,
        },
      ],

      cafe_list: [
        {
          id: 1,
          name: "Relax",
          persons_now: 2,
          img: require("../assets/img/restaurant/Cafe.png"),
          distance: 500,
        },
        {
          id: 2,
          name: "Relax",
          persons_now: 2,
          img: require("../assets/img/restaurant/Cafe.png"),
          distance: 500,
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.search {
  padding: 7vh 4% 6vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-left: 0.8%;
    margin-right: 1.3%;
    margin-bottom: 5%;
  }

  .filter {
    margin-top: 1px;
  }

  &__content {
    text-align: left;

    .title {
      margin-bottom: 8px;
      font-size: 32px;
    }
  }

  .srch {
    width: 24px;
    height: 24px;
    background: no-repeat center center
      url("../assets/img/restaurant/Search.svg");
  }
}

.scroll-dating {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 22px 0px 15px;

  &__item {
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(78, 60, 33, 0.08);
    border-radius: 12px;
    padding: 12px 1px 12px 11px;
    max-width: 48%;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }

  &__img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f2f1f0;
  }

  &__content {
    font-size: 12px;

    &.text-base {
      line-height: 15px;
    }
  }

  &__bio {
    margin: auto 8px !important;
  }

  .title {
    font-size: 13px;
    margin: 0;
  }
}

.line {
  border-bottom: 1px solid #e6e4e3;
}

.rest_list {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  position: relative;
  overflow: overlay;

  &__item {
    min-height: 168px;
    min-width: 279px;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    box-shadow: 0px 4px 24px rgba(78, 60, 33, 0.08);
    border-radius: 12px;
    margin-bottom: 16px;
  }

  &__bio {
    text-align: left;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  &__distance {
    position: absolute;
    background: rgba(54, 38, 20, 0.72);
    border-radius: 29px;
    padding: 4px 12px 0;
    top: 4%;
    left: 3%;
    color: var(--color-white);
    font-size: 13px;
  }

  &__photo {
    min-height: 160px;
    position: relative;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    width: 65%;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .title {
    font-size: 20px;
  }

  .to_rest {
    width: 24px;
    height: 24px;
    background: no-repeat center center
      url("../assets/img/restaurant/Right.svg");
  }
}
</style>
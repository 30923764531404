<template>
  <modal-base :close="false">
    <header class="header">
      <div class="close"></div>
      <h2 class="title">Who am I looking for?</h2>
      <div class="ready text-base">Ready</div>
    </header>
    <div class="answer">
      <div class="line"></div>
      <div class="answer__content">
        <div class="answer__text text-base">
          Looking for a handsome man to start a family
        </div>
      </div>
      <div class="line"></div>
      <div class="show">
        <span class="text-base">Hide a question from your profile</span>
        <div class="date_checbox">
          <CheckboxLong />
        </div>
      </div>
    </div>
  </modal-base>
</template>
<script>
import ModalBase from "./UI/ModalBase.vue";
import CheckboxLong from "./UI/CheckboxLong.vue";

export default {
  name: "ModalBirthDate",
  components: {
    ModalBase,
    CheckboxLong,
  },
  data() {
    return {
      options: [],
      selected: "",
    };
  },
  methods: {
    onClick(option) {
      this.selected = option;
      this.$emit("select", { selected: option });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .title {
    font-size: 20px;
    line-height: 24px;
  }

  .ready {
    color: var(--color-main-light);
  }
}

.close {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: no-repeat center center var(--color-light-10)
    url("../assets/img/Close-light.svg");
}

.answer {
  padding: 0 16px;
  position: relative;

  &__content {
    height: 72vh;
    margin-top: 24px;
  }
}

.line {
  border-bottom: 1px solid #e6e4e3;
}

.show {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: center;
}
</style>